/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, Getter, Mutation, State } from 'vuex-class'
import { ASMRModelChange } from '@/store/asmr/types';
const namespace: string = 'asmr';

export default class AsmrVideoChoicePage extends Vue {
	@State('asmr') asmr: ASMRModelChange | undefined;
	@Getter('getModelItem', {namespace}) getModelItem: any;
	@Getter('getArrVideo', {namespace}) getArrVideo: any;
	@Action('getAsmrModelsVideo', {namespace}) getAsmrModelsVideo: any;

	selectedVideo() {
		this.$router.push({ name: 'asmr-video-chosen' }).then(r => r);
	}

	created() {
		this.getAsmrModelsVideo()
	}
}
